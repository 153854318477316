export const getGoogleGeocodeDetails = (response) => {
  return response.reduce((acc, current) => {

    switch (current.types[0]) {
      case 'street_number':
        acc.addressNumber = current.long_name;
        break;
      case 'route':
        acc.addressStreet = current.long_name;
        break;
      case 'administrative_area_level_2':
        acc.addressCity = current.long_name;
        break;
      case 'administrative_area_level_1':
        acc.addressState = current.short_name;
        break;
      case 'country':
        acc.addressCountry = current.short_name;
        break;
      case 'postal_code':
        acc.addressZip = current.long_name;
        break;
      default:
        break;
    }

    return acc;
  }, {});
};
