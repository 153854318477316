export const addSDK = async (sdkId, src) => {
  const findScript = document.querySelector(`#${sdkId}`);
  if (findScript) {
    return Promise.resolve();
  }

  const script = document.createElement('script');
  script.async = true ;
  script.setAttribute('id', sdkId);
  script.setAttribute('src', src);
  document.head.appendChild(script);
  return new Promise((resolve, reject) => {
    script.onload = (result) => {
      resolve(result);
    };
    script.onerror = (e) => {
      reject(e);
    };
  });
};

export const removeSDK = (path) => {
  const script = document.querySelector(path);
  if (script) {
    script.remove();
    return true;
  }
  return;
};

export const createPlaidWidget = async (token, plaidPlugin, methods) => {
  const clientName = process.env.VUE_APP_PLAID_ACH_CLIENT_NAME;
  const env = process.env.VUE_APP_PLAID_ENV;

  if (!plaidPlugin) {
    return;
  }

  return await plaidPlugin.create({
    clientName,
    env,
    token,
    product: ['auth'],
    onSuccess: (public_token, metadata) => methods.onSuccess(public_token, metadata),
  });
};


export const createSocureWidget = async (token, socurePlugin) => {
  if (!socurePlugin) {
    return;
  }

  return await socurePlugin.init(token);
};
