<template>
  <KycAddressForm
    :googleAutocompleteService="googleAutocompleteService"
    :googleGeocoder="googleGeocoder"
    :storedForm="getUserInformationAddress"
    :patchProfile="patchProfile"
    @submit="next"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'; 

import KycAddressForm from '@/modules/user/Modals/KycAddressForm/index.vue';
import { addSDK, removeSDK } from '@/utils/sdk';
import KYC_LITE_STEPS from '@/enums/KycLiteSteps';
import adaptAttemptLevelUpForEmit from '@/modules/user/utils/adapt-attempt-level-up-for-emit.js';

export default {
  name: 'KycAddress',
  components: {
    KycAddressForm
  },

  props: {
    patchProfile: Boolean,
  },

  data:() => ({
    googleSdkId: 'google-places-sdk',
    googleAutocompleteService: {},
    googleGeocoder: {},
  }),

  computed: {
    ...mapGetters('user', ['getKycLevelForAmplitude', 'getUserInformationAddress']),
  },

  methods: {
    ...mapActions('ui', ['setModalProps']),
    ...mapActions('user', [
      'postKycLite',
      'updateAddress',
      'updateKycLiteStep',
    ]),

    previous() {
      this.updateKycLiteStep(KYC_LITE_STEPS.BASIC);
    },
    

    setTheGoogleProps(){
      let timer = setTimeout(() => {
        if (window.google) {
          this.googleAutocompleteService = new window.google.maps.places.AutocompleteService();
          this.googleGeocoder = new window.google.maps.Geocoder();
          clearTimeout(timer);
        } else {
          this.setTheGoogleProps();
        }
      }, 1000);
    },

    async mountGooglePlacesAutoComplete(){ 
      await addSDK(this.googleSdkId,`${process.env.VUE_APP_GOOGLE_PLACES_URL}?key=${process.env.VUE_APP_GOOGLE_PLACES_ID}&libraries=places&v=weekly&channel=2`);
      this.setTheGoogleProps();
    },

    async next(value) {
      this.isLoading = true;
      await this.updateAddress(value);

      try {
        const response = await this.postKycLite();

        const emitVariables = adaptAttemptLevelUpForEmit(response);

        this.$store.dispatch('events/track', {
          event: 'KYC_LITE_SUBMITTED',
          variables: {
            ...emitVariables,
          },
        });
      } catch(e) {
        this.showError(e);
      } finally {
        this.isLoading = false;
      }
    },
  },

  mounted() {
    this.setModalProps({
      hasPrevious: true,
      handlePrevious: this.previous,
      isClosable: false
    });
    this.mountGooglePlacesAutoComplete();
    this.$store.dispatch('events/track', {
      event: 'KYC_ADDRESS_VIEWED',
      variables: {
        ...this.getKycLevelForAmplitude,
      },
    });
  },

  destroyed(){
    removeSDK(this.googleSdkId);
    if (this.addressInterval) {
      clearInterval(this.addressInterval);
    }
  }
};
</script>

<style scoped>
</style>
