<template>
  <form :class="containerClasses" @submit.prevent="onSubmit">
    <h1 class="headline-small">
      {{ $t("kyc.modal.address.title") }}
    </h1>

    <p class="body-text-large">{{ $t("kyc.modal.address.description") }}</p>

    <div class="flex flex-col gap-s12">
      <div v-click-outside class="relative flex flex-col gap-s8">
        <CustomInput
          data-cy="input-address"
          data-testid="input-address"
          @input="debounceAddressStreet"
          initialFocus
          tabindex="0"
          data-lpignore="true"
          v-model="form.addressStreet"
        >
          <label class="body-text-x-large">{{ $t("address.street") }}</label>
        </CustomInput>
        <AutocompletionContainer
          :visible="isAutocompletionReady"
          :data-testid="`autocomplete-${
            isAutocompletionReady ? 'visible' : 'hidden'
          }`"
          class="autocomplete-position"
        >
          <AutocompletionItem
            data-cy="autocomplete-option"
            data-testid="autocomplete-option"
            :key="key"
            tabindex="0"
            v-for="(item, key) in autocomplete.options"
            @click="() => changeTheAddressTo(item)"
          >
            {{ item.description }}
          </AutocompletionItem>
        </AutocompletionContainer>
      </div>

      <div class="relative flex flex-col gap-s8">
        <CustomInput
          data-cy="input-complement"
          data-testid="input-complement"
          data-lpignore="true"
          v-model="form.addressComplement">
          <label class="body-text-x-large">{{
            $t("address.complement")
          }}</label>
        </CustomInput>
      </div>

      <div class="relative flex flex-col gap-s8">
        <CustomInput
          data-cy="input-city"
          data-testid="input-city"
          data-lpignore="true"
          v-model="form.addressCity">
          <label class="body-text-x-large">{{ $t("address.city") }}</label>
        </CustomInput>
      </div>

      <div class="relative flex gap-s24">
        <div class="flex flex-col w-full gap-s8">
          <label class="body-text-x-large" v-text="$t('address.state')" />
          <CustomSelect
            data-cy="select-state"
            data-testid="select-state"
            class="mt-0"
            :items="filteredUsStates"
            :tabindex="0"
            :selectedItem="form.addressState"
            :isOpen="false"
            :visibleItems="4"
            testId="state-select"
            @onSelectItem="selectState"
          />
        </div>

        <div class="flex flex-col w-full gap-s8">
          <CustomInput
            data-cy="input-zip"
            data-testid="input-zip"
            @input="handleZipChange"
            data-lpignore="true"
            v-model="form.addressZip"
          >
            <label class="body-text-x-large">{{ $t("address.zip") }}</label>
          </CustomInput>
        </div>
      </div>
    </div>

    <ButtonV2
      data-cy="button-continue"
      data-testid="button-continue"
      :inactive="isLoading || isFormInvalid"
      :label="$t('continue')"
      submit
      wide
    />
  </form>
</template>

<script>
import {
  AutocompletionContainer,
  AutocompletionItem,
  CustomInput,
  CustomSelect,
} from '@/components/misc';
import ButtonV2 from '@/stories/misc/ButtonV2';
import usStates from '@/utils/usStates.json';
import { getGoogleGeocodeDetails } from '@/modules/user/utils/get-google-geocode-details.js';

export default {
  name: 'KycAddressForm',
  components: {
    AutocompletionContainer,
    AutocompletionItem,
    ButtonV2,
    CustomInput,
    CustomSelect,
  },

  props: {
    googleAutocompleteService: Object,
    googleGeocoder: Object,
    patchProfile: Boolean,
    storedForm: Object,
  },

  data: () => ({
    timer: null,
    autocomplete: {
      active: false,
      options: [],
    },
    form: {
      addressCountry: '',
      addressComplement: '',
      addressStreet: '',
      addressCity: '',
      addressState: '',
      addressZip: '',
    },
    usStates,
    isLoading: false,
  }),

  computed: {
    containerClasses() {
      return {
        'flex flex-col justify-between gap-s20': true,
        'address-container': !this.isMobileDevice,
        'w-full': this.isMobileDevice,
      };
    },
    isFormInvalid() {
      return (
        !this.form.addressStreet ||
        !this.form.addressCity ||
        !this.form.addressState ||
        !this.form.addressZip ||
        this.form.addressZip.length !== 5
      );
    },
    isAutocompletionReady() {
      return [
        this.autocomplete.options.length > 0,
        this.autocomplete.active,
        !!this.form.addressStreet,
      ].every((item) => item === true);
    },
    filteredUsStates() {
      return this.usStates.map((item) => item.state);
    },
  },

  directives: {
    'click-outside': {
      bind: function (el, _binding, vnode){
        el.clickHandler = (event) => vnode.context.toggleAutocompletion(event, el);
        window.addEventListener('click', el.clickHandler);
        window.addEventListener('keyup', el.clickHandler);
      },
    },
  },

  methods: {
    async getGoogleAddresses(input) {
      const response = await new Promise((resolve) => {
        this.googleAutocompleteService.getPlacePredictions(
          { 
            input,
            componentRestrictions: {
              country: 'us'
            },
          },
          (e) => {
            if (e) {
              resolve(e);
            }
            resolve([]);
          }
        );
      });

      if (response.length > 0) {
        this.autocomplete.options = response;
        this.autocomplete.active = true;
      }
    },

    async requestDetailsFromGeocode(placeId){
      return await new Promise((resolve) => {
        this.googleGeocoder.geocode({
          placeId
        }, (geocodeResponse) => {
          if (Array.isArray(geocodeResponse)) {
            const adaptedResponse = getGoogleGeocodeDetails(geocodeResponse[0].address_components);
            resolve(adaptedResponse);
          }
        });
      });

    },

    async changeTheAddressTo(obj){
      const response = await this.requestDetailsFromGeocode(obj.place_id);

      if (response.addressNumber) {
        this.form.addressStreet = `${response.addressNumber} ${response.addressStreet}`;
      } else {
        this.form.addressStreet = response.addressStreet;
      }

      this.form.addressCity = response.addressCity;
      this.form.addressState = response.addressState;
      this.form.addressZip = response.addressZip;

      this.clearAutocompletion();
    },

    clearAutocompletion(){
      this.autocomplete.options = [];
      this.autocomplete.active = false;
    },

    toggleAutocompletion(event, el){
      if (!event.key && event.target.tagName === 'BUTTON' && el.contains(event.target)) {
        this.autocomplete.options = [];
        this.autocomplete.active = false;
        return;
      }
      this.autocomplete.active =
        event.target === el || el.contains(event.target);
    },
    debounce(callback, timeout = 300) {
      return () => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => callback(), timeout);
      };
    },
    debounceAddressStreet() {
      this.debounce(
        () => this.getGoogleAddresses(this.form.addressStreet),
        300
      )();
    },
    updateDataWithStore() {
      const {
        addressStreet,
        addressCity,
        addressCountry,
        addressState,
        addressZip,
      } = this.storedForm;

      this.form = {
        ...this.form,
        addressStreet,
        addressCity,
        addressCountry,
        addressState,
        addressZip,
      };
      this.selectState(addressState);
    },
    selectState(state) {
      this.form.addressState = state;
    },
    handleZipChange() {
      const onlyNumbers = this.form.addressZip
        .replace(/\D+/g, '')
        .substring(0, 5);
      this.form.addressZip = onlyNumbers;
    },
    onSubmit() {
      this.isLoading = true;
      this.$emit('submit', this.form);
    },
  },

  mounted() {
    this.updateDataWithStore();
  },
};
</script>

<style scoped>
.autocomplete-position {
  top: calc(100% - 35px);
}

.address-container {
  max-width: 450px;
}
</style>
